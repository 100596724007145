/* Sticky Background */
.devops-page {
  position: relative;
  background-size: cover;
  min-height: 100vh;
  padding: 20px; 
  margin-top: 4rem;
  /* background: rgb(224,224,224);
background: radial-gradient(circle, rgba(224,224,224,1) 0%, rgba(82,207,201,1) 100%); */
}

/* Icon at the top-right corner */
.icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 15px;
  background: purple;
  z-index: 1000;
  padding: 9px;
  border-radius: 10px;
  border: 1px solid #fffbfb5e;
}

/* Side dropdown styles */
.side-dropdown {
  position: fixed;
  overflow: auto;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  transition: right 0.3s ease;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.close-btn-container {
  margin-left: 12rem;
  font-size: 1.5rem;
}

.side-dropdown.open {
  right: 0;
}

.side-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-dropdown li {
  padding: 15px 20px;
  border-bottom: 1px solid #444;
  cursor: pointer;
}

.side-dropdown li:hover {
  background-color: #555;
}



.devops-circle {
  position: absolute;
  border-radius: 50%;
  filter: blur(20px);
  z-index: -1;
}

.devops-circle-bottom-left {
  bottom: 0;
  left: 0;
  width: 800px;
  height: 800px;
  background: rgba(148, 0, 211, 0.4);
  /* Slightly different purple */
  animation: moveCircleBottomLeft 8s infinite alternate;
}

@keyframes moveCircleBottomLeft {
  0% {
    transform: translate(-20px, -20px);
  }

  100% {
    transform: translate(20px, 20px);
  }
}

/* Content Container */
.content-container-devops {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent background */
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/* Left Side Content */
.left-content {
  width: 60%;
 
}

/* Right Side Form */
.devops-contact-form {
  width: 35%;
  height: 35%;
  background-color: #250235;
  padding: 20px;
  border-radius: 10px;
}

/* Content Container */
.content-container {
  position: relative;

  /* Semi-transparent background */
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Left Side Content */
.left-content {
  width: 60%;
}

.ratings-info {
  margin-bottom: 13px;
  font-size: 16px;
  color: #0077b6;
  display: flex;
  align-items: center;
  gap: 8px; /* Space between elements */
  flex-wrap: wrap;
}

.rating {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.stars-section {
  display: flex;
  gap: 5px; /* Space between stars */
  /* margin-bottom: 10px; Space below stars */
}

.star-icon {
  color: gold;
  font-size: 18px; /* Adjust size of stars */
}


.separator {
  color: #333;
  font-weight: bold;
}

/* Right Side Form */
.devops-contact-form {
  width: 35%;
  background-color: #250235;
  padding: 20px;
  border-radius: 10px;
}

/* Heading */
h1 {
  font-size: 36px;
  margin-bottom: 2px;
}

h3 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.main-heading {
  margin-top: 1rem;
}

/* Points List */
.points-list {
  list-style: none;
  padding: 0;
}

.points-list li {
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.points-list li svg {
  margin-right: 10px; /* Adjust spacing between icon and text */
  color: #1194e0; /* Optional: change icon color */
}

/* Contact Form */
.contact-form-wrapper {
  display: flex;
  flex-direction: column;
}

.contact-form-wrapper label {
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-form-wrapper input,
.contact-form-wrapper select {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #64037c;
  border-radius: 5px;
}

/* Submit Button */
.submit-btn {
  background-color: #64037c;
  /* New button color */
  color: #ffffff;
  border: none;
  padding: 12px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #8509a6;
}

.video-section {
  margin-top: 3%;
  position: relative;
  width: 80%;
  height: 80vh;
  left: 10%;
  overflow: hidden;
  background-color: #000;
}

.video-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 1;
  /* Ensures video is behind other elements */
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Roadmap Section */
.roadmap-section {
  padding: 60px 20px;
  margin-top: 3%;
  background-color: rgba(255, 255, 255, 0.1);
}

.roadmap-section h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
  color: #000000b8;
}

.roadmap-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
}

.roadmap-item {
  background-color: rgba(255, 253, 253, 0.2);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.roadmap-item:hover {
  transform: translateY(-5px);
}

.roadmap-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  background: rgb(28, 152, 237);
  background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
  color: #ffffff;
}

.roadmap-title h3 {
  margin: 0;
  font-size: 20px;
}

.roadmap-title span {
  font-size: 18px;
}

.roadmap-subpoints {
  margin-top: 1%;
  list-style-type: disc;
  padding: 0 20px 20px 40px;
  color: #000000;
  
}

.roadmap-subpoints li {
  margin-bottom: 10px;
  font-size: 16px;
}

.key-features-section {
  padding: 40px 0;
  background-color: #f9f9f9;
}

.features-container {
  display: flex;
  justify-content: space-between;
}

.features-left,
.features-right {
  width: 45%;
}

.feature-item {
  margin-bottom: 20px;
  font-size: 1.1em;
  display: flex;
  align-items: center;
}

.feature-item i {
  color: #007bff;
  margin-right: 10px;
}

/* Key Features Section */
/* DevOps.css */
.devops-key-features {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  background-color: #E6F7FF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-heading {
  font-size: 2rem;
  text-align: center;
  color: #4c92be;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.devops-features-container {
  display: flex;
  gap: 2rem;
}

.devops-features-left, .devops-features-right {
  flex: 1;
}

.devops-feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #333;
}

.devops-feature-item i {
  color: #1f88d2;
  font-size: 1.2rem;
  margin-right: 0.75rem;
}

.devops-feature-item:hover i {
  color: #078de0;
}

.devops-feature-item:hover {
  color: #00bcf5;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}


/* Batch Schedule Section Styles */
.batch-schedule-section {
  margin: 5px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.batch-schedule-section h2 {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 15px;
}

.batch-schedule-section p {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 25px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Batch Schedule Table Styles */
.batch-schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.batch-schedule-table th,
.batch-schedule-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.batch-schedule-table th {
  background-color: #4b026d;
  color: white;
  font-weight: 600;
}

.batch-schedule-table td {
  background-color: rgba(255, 255, 255, 0.2);
}

.batch-schedule-table a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.batch-schedule-table a:hover {
  color: #cc00b1;
}
/* media query */
.faq-section {
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.faq-section::before {
  content: "";
  position: absolute;
  top: 10%;
  left: -100px;
  width: 200px;
  height: 200px;
  background: rgba(128, 0, 128, 0.4);
  filter: blur(30px);
  border-radius: 50%;
  animation: moveShape 6s ease-in-out infinite;
  z-index: -1;
}

@keyframes moveShape {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(0);
  }
}

.faq-section::after {
  content: "";
  position: absolute;
  bottom: 2%;
  right: -100px;
  width: 200px;
  height: 200px;
  background: rgba(128, 0, 128, 0.4);
  filter: blur(30px);
  border-radius: 50%;
  animation: moveShapeReverse 6s ease-in-out infinite;
  z-index: -1;
}

@keyframes moveShapeReverse {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0);
  }
}

.faq-section h1 {
  text-align: center;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
}

.faq-question {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #cbbecc;
  border-radius: 7px;
  cursor: pointer;
  transition: background 0.3s;
  position: relative;
}

.faq-question::after {
  content: "+";
  font-size: 24px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.faq-item.active .faq-question::after {
  content: "-";
}

.faq-question h3 {
  margin: 0;
  font-size: 18px;
}

.faq-question:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.faq-answer {
  overflow: hidden;
  height: 0;
  transition: height 0.5s ease-out, padding 0.5s ease-out;
  background-color: rgba(255, 255, 255, 0.1);
}

.faq-item {
  margin-bottom: 10px;
  border-radius: 7px;
}

.faq-item.active .faq-answer {
  height: auto; /* Allows smooth transition */
  padding: 15px;
}

.faq-item:not(.active) .faq-answer {
  padding: 0;
}


.devops-certification-section {
  margin: 50px 0;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.certification-heading {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: #000000b8;

}

.certification-content {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.certification-points {
  flex: 1;
  margin-right: 20px;
}

.certification-points ul {
  list-style-type: disc;
  padding-left: 20px;
}

.certification-points li {
  font-size: 18px;
  margin-bottom: 10px;
  color: #000000b8;

}

.certification-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.certification-image img {
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-question h3 {
    font-size: 12px;
  }

  .faq-question {
    padding: 10px;
  }

  .faq-section h1 {
    font-size: 20px;
    margin-top: -1rem;
  }

  .devops-page {
    position: relative;
    background-size: cover;
    min-height: 100vh;
    padding: 5px;
    margin-top: 4rem;
}
  .devops-feature-item {
    font-size: 13px;
  }

  .devops-key-features h2 {
    font-size: 14px;
    margin-top: -1rem;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .left-content,
  .devops-contact-form {
    width: 100%;
    margin-bottom: 20px;
  }

  .points-list {
    padding-left: 0;
  }

  .points-list li {
    font-size: 0.9em;
  }

  .video-section {
    margin-top: 2rem;
    width: 100%;
    height: 40vh;
    left: 0;
  }

  .video-container {
    width: 100%;
  }

  .roadmap-section {
    padding: 40px 10px;
  }

  .roadmap-section h2 {
    margin-top: -1rem;
    font-size: 20px;
    margin-bottom: -2rem;
  }

  .roadmap-title {
    padding: 10px;
  }

  .roadmap-title h3 {
    font-size: 13px;
  }

  .roadmap-subpoints li {
    font-size: 14px;
  }

  .devops-features-container {
    flex-direction: column;
  }

  .devops-features-left,
  .devops-features-right {
    width: 100%;
  }

  .batch-schedule-section h2 {
    font-size: 22px;
  }

  .batch-schedule-section p {
    font-size: 14px;
  }

  .batch-schedule-table th,
  .batch-schedule-table td {
    padding: 10px;
    font-size: 14px;
  }

  .certification-content {
    flex-direction: column;
    text-align: center;
  }

  .certification-points {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: start;
  }

  .certification-heading {
    font-size: 24px;
  }

  .certification-points ul {
    padding-left: 10px;
  }

  .certification-points li {
    font-size: 16px;
  }

  .certification-image img {
    width: 80%;
  }
}

.alumni-section {
  position: relative;
  width: 100vw;
  height: 100%;
  margin-top: 5%;
}

.alumni-section h1 {
  font-size: 36px;
  color: #ffffff;
  z-index: 2;
  text-align: center;
}

.full-screen-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-image img {
  width: 60%;
  height: auto;
  object-fit: cover;
  z-index: 1;
  /* Image is below the heading */
}

.devops-projects-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.swiper-slide-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  /* Adjust this based on your image dimensions */
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.project-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

@media (max-width: 480px) {
   .content-container-devops {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  /* Adjust left content and form to stack vertically */
  .left-content {
    width: 100%;
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .devops-contact-form {
    width: 100%;
    height: auto; /* Allow the form to expand naturally */
    padding: 0;
  }

  /* Adjust the heading sizes for better readability */
  h1 {
    font-size: 28px;
    text-align: center;
  }

  h3 {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
  }

  /* Points List adjustments */
  .points-list li {
    font-size: 16px;
  }

  /* Contact Form fields should be full-width */
  .contact-form-wrapper input,
  .contact-form-wrapper select {
    width: 100%;
    font-size: 16px;
  }

  /* Adjust Submit Button */
  .submit-btn {
    width: 100%;
    font-size: 16px;
    padding: 14px;
  }
  .roadmap-container h1 {
    font-size: 1.5rem;
    margin: -2rem 1rem 1rem 1rem;
  }

  .full-screen-image {
    padding: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .full-screen-image img {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alumni-section {
    margin: 0 -1.3rem;
  }

  .alumni-section h1 {
    text-align: center;
    font-size: 24px;
  }
  .batch-schedule-section h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .batch-schedule-section p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  /* Table wrapper to handle overflow */
  .batch-schedule-table-wrapper {
    overflow-x: auto; /* Ensures horizontal scrolling on small screens */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  }

  .batch-schedule-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto; /* Allows table to adjust based on content */
  }

  .batch-schedule-table th,
  .batch-schedule-table td {
    padding: 10px;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .batch-schedule-table th {
    /* background-color: ; */
    font-weight: bold;
  }

  /* Responsive styles */
  @media (max-width: 480px) {
    .batch-schedule-section h2 {
      font-size: 20px;
    }

    .batch-schedule-section p {
      font-size: 13px;
    }

    .batch-schedule-table-wrapper {
      overflow-x: auto; /* Ensures the table remains scrollable */
    }

    .batch-schedule-table th,
    .batch-schedule-table td {
      font-size: 12px;
      padding: 6px;
    }

    .batch-schedule-table th,
    .batch-schedule-table td {
      white-space: nowrap; /* Prevents text wrapping for better readability */
    }

    .batch-schedule-table {
      width: 600px; /* Sets a minimum width for the table */
    }
  }
}

/* Container Style */
.slider-container {
  margin: 40px auto;
  padding: 30px 20px;
  max-width: 1100px;
  /* Increased max width for a more spacious look */
  background: rgba(255, 255, 255, 0.125);
  border-radius: 15px;
  border: 1px solid gray;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  /* Center the text content inside the container */
}

/* Slider Item Style */
.slider-item {
  width: 100%;
  padding: 0 15px;
  /* Slightly increased padding for more spacing */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* Arrange content in a column */
  align-items: center;
  /* Center-align all content */
  justify-content: center;
  /* Center content vertically */
}

/* Slider Image Style */
.slider-image {
  width: 100%;
  height: auto;
  /* Increased height for more visual impact */
  object-fit: cover;
  /* Cover the entire slider area nicely */
  border-radius: 15px;
  /* More rounded corners */
  margin-bottom: 15px;
  /* Space below the image for text */
  padding: 8px;
  /* Padding around the image */
  /* border: 2px solid black; Uncomment if needed */
}
