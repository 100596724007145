.marquee-container {
  text-align: center;
  padding: 20px;
  /* background-color: #f0f0f0; */
}

.marquee-heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.logo {
  height: 180px;
  margin-right: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .marquee-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .marquee-container {
    text-align: center;
    padding: 10px;
  }

  .logo {
    height: 100px;
  }
}