.learn-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-top: 4rem;
  }
  
  .learn-container h1 {
    font-size: 24px;
    color: #003366;
    margin-bottom: 20px;
    text-align: center;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  .learn-points {
    list-style-type: none;
    padding: 0;
  }
  
  .learn-points li {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.6;
    display: flex;
    align-items: center;
  }
  
  .learn-points li:before {
    content: '✔';
    color: #007bff;
    margin-right: 10px;
  }
  
  .learn-labels {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  
  .label {
    flex: 1;
    text-align: center;
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f9f9f9;
    min-width: 150px;
    position: relative; /* Required for ::after positioning */
    overflow: hidden; /* Ensures the pseudo-element stays within bounds */
  }
  
  .label::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%; /* Start from the middle */
    width: 0; /* Initially, no width */
    height: 3px; /* Height of the border */
    background-color: #007bff;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    transform: translateX(-50%);
  }
  
  .label:hover::after {
    width: 100%; /* Expands to full width on hover */
    left: 50%; /* Keeps the animation centered */
    transform: translateX(-50%);
  }
  
  .label i {
    font-size: 30px;
    color: #007bff;
  }
  
  .label p {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .learn-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .enroll-button, .demo-button {
    padding: 10px 20px;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
 
  .demo-button {
    transition: background-color 0.3s ease;
    background: rgb(28, 152, 237);
    background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
  }
  .demo-button:hover{
    background: rgb(24, 72, 160);
  background: linear-gradient(251deg, rgba(24, 72, 160, 1) 35%, rgba(28, 152, 237, 1) 100%);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .learn-labels {
      justify-content: center;
      gap: 10px;
    }
  
    .label {
      padding: 8px;
      margin: 5px 0;
      font-size: 12px;
    }
  
    .label i {
      font-size: 24px;
    }
  
    .learn-buttons {
      flex-direction: column;
      align-items: center;
    }
  
    .demo-button {
      padding: 8px 16px;
      font-size: 14px;
      width: 80%;
    }
  }
  