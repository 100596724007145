/* Container Styling */
.contact-card-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #e6f7ff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Training Details */
.training-details {
  margin-bottom: 20px;
}

.training-icon {
  font-size: 32px;
  color: #0077b6;
  margin-bottom: 10px;
  justify-self: center;
}

.training-title {
  font-size: 20px;
  color: #0077b6;
  font-weight: bold;
  margin-bottom: 5px;
}

.training-subtitle {
  font-size: 16px;
  color: #005f91;
  margin-bottom: 15px;
}

.job-assistance {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  color: #005f91;
}

.assistance-icon {
  font-size: 20px;
  color: #0077b6;
 
}

/* Toggle Container */
/* Training Type Buttons */
.training-type-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.training-type-btn {
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #0077b6;
  border-radius: 5px;
  background-color: #f0f8ff;
  color: #0077b6;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.training-type-btn.active {
  background-color: #0077b6;
  color: white;
}

.training-type-btn:hover {
  background-color: #005f91;
  color: white;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0077b6;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 16px;
  color: #005f91;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .switch {
    width: 50px;
    height: 30px;
  }

  .slider:before {
    width: 22px;
    height: 22px;
  }

  .toggle-label {
    font-size: 14px;
  }
}

/* Action Buttons */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

/* Enroll Button */
.enroll-btn {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background: rgb(28, 152, 237);
  background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
  color: white;
  cursor: pointer;

}

.enroll-btn:hover {
  background: rgb(24, 72, 160);
  background: linear-gradient(251deg, rgba(24, 72, 160, 1) 35%, rgba(28, 152, 237, 1) 100%);
}

/* Syllabus Button */
.syllabus-btn {
  font-size: 16px;
  padding: 10px 20px;
  border: 1px solid #0077b6;
  border-radius: 5px;
  background-color: #f0f8ff;
  color: #0077b6;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.syllabus-btn:hover {
  background-color: #0077b6;
  color: white;
}

/* Query Section */
.queries-section {
  margin-top: 20px;
}

.queries-heading {
  font-size: 18px;
  color: #005f91;
  margin-bottom: 10px;
}

.contact-number {
  font-size: 16px;
  color: #0077b6;
  display: inline-flex; /* Use inline-flex to keep it on one line */
  align-items: center;  /* Center-align the icon and text vertically */
  gap: 5px;             /* Adds space between the icon and text */
}
.phone-icon {
  font-size: 18px;
}


.availability {
  font-size: 14px;
  color: #005f91;
}
