/* Main Contact Section */
.contact {
    background-color: #EFEAE3;
    margin-bottom: 75%;
    border-bottom-left-radius: 2%;
    border-bottom-right-radius: 2%;
    padding: 2rem;
    /* margin-top: 4rem; */
  }
  
  /* Contact Page Wrapper */
  .contact-page {
    background-image: url('../assets/contact.webp'); /* Background image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding: 20px;
    margin-top: 4rem;
  }
  
  /* Contact Text Content */
  .contact-content {
    background-color: rgba(255, 255, 255, 0.8); /* White background with opacity for text */
    border-radius: 8px;
    padding: 30px;
    max-width: 600px;
    margin-bottom: 40px;
    margin-top: 20px; /* Add top margin for spacing */
  }
  
  .contact-text h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-text p {
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Main Card for Contact Details */
  .main-card {
    width: 96%;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Contact Details Layout */
  .contact-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto; /* Center the contact details */
    width: 100%;
  }
  
  /* Individual Contact Item */
  .contact-item {
    flex: 1 1 18%; /* Default width for larger screens */
    min-width: 150px; /* Minimum width to prevent squishing */
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    margin: 10px;
    transition: transform 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-item:hover {
    transform: translateY(-10px); /* Hover effect */
  }
  
  /* Icon Container and 3D Effect */
  .icon-container-contact {
    display: inline-block;
    perspective: 1000px; /* Perspective for 3D effect */
  }
  
  .contact-icon-c {
    font-size: 2rem;
    color: #007bff;
    transition: transform 0.5s;
  }
  
  .icon-container-contact:hover .contact-icon-c {
    transform: rotateY(180deg); /* Flipping effect */
  }
  
  /* Contact Item Text */
  .contact-item h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  
  .contact-item p {
    font-size: 1rem;
    color: #555;
  }
  
  .form-map-container {
    width: 100vw; /* Full screen width */
    margin-left: calc(50% - 50vw); /* Center-align full width */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Light background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    gap: 20px; /* Space between form and map */
}

/* Left Side - Form */
.form-container {
    flex: 1;
    max-width: 600px;
}

/* Right Side - Map */
.map-container {
    flex: 1;
    height: 450px;
    max-width: 600px; /* Max width for map on larger screens */
}
@media (max-width: 1024px) {
  .form-container, .map-container {
      max-width: 50%; /* Allow both sections to take half width on medium screens */
  }
}
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-page {
      background-image: url('../assets/contact.webp'); /* Background image */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
    }
  
    .contact-content {
      max-width: 100%;
      position: relative;
      top: 20px;
      left: 3px; /* Allow content to use full width */
    }
  
    .contact-details {
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center-align contact items */
    }
  
    .contact-item {
      width: 100%; /* Full width for each contact item */
      margin-bottom: 20px; /* Add margin for spacing */
      max-width: 300px; /* Limit max width for smaller devices */
    }
    .form-map-container {
      flex-direction: column; /* Stack form and map vertically */
      align-items: center;
      padding: 15px;
      width: 100%;
      margin-left: 0; /* Center-align for full width */
      gap: 15px; /* Reduce spacing */
  }

  .form-container, .map-container {
      max-width: 100%;
      margin-bottom: 20px; 
      margin-right: 20px;/* Spacing between stacked elements */
  }

  .map-container iframe {
      width: 100%; /* Make iframe responsive */
      height: 300px; /* Adjust height on smaller screens */
  }
  }
  
  @media (max-width: 480px) {
    .contact-text h1 {
        font-size: 1.8rem; /* Smaller font size for heading */
      }
    
      .contact-text p {
        font-size: 1rem; /* Smaller font size for paragraph */
      }
    
      .contact-item h3 {
        font-size: 1rem; /* Adjust heading size */
      }
    
      .contact-item p {
        font-size: 0.9rem; /* Adjust paragraph size */
      }
    
      .contact-item {
        padding: 15px; /* Reduce padding */
        margin-bottom: 15px; /* Adjust spacing */
        max-width: 50%; /* Further reduce width for very small screens */
      }
     
      .icon-container {
        margin-bottom: 10px; /* Add spacing below icons */
      }
      .form-container {
        padding: 10px; /* Reduce padding on small screens */
    }
    .map-container iframe {
        height: 250px; /* Further reduce height for mobile */
    }
  }
  

