@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

.footer {
  background-color: #f8f9fa;
  color: #333;
  font-family: Arial, sans-serif;
}

.newsletter {
  background-color: #343a40;
  color: #fff;
  padding: 15px 0;
}

.container {
  max-width: 1200px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 0 auto;
}

.left h1 {
  margin: 0;
}

.left p {
  margin: 5px 0 0;
}

.right {
  display: flex;
  align-items: center;
}

.right input {
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  width: 250px;
  /* Adjust width as needed */
}

.subscribe-btn {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

footer {
  padding: 20px 0;
  background-color: #f8f9fa;
}

.footer-box {
  padding: 10px;
  text-align: start;
}

.logo-main img {
  width: 220px;
  height: 75px;
}

.footer-box h3 {
  margin-bottom: 10px;
  color: #007bff;
}

.footer-box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-box ul li {
  gap: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.footer-box .social-icons {
  margin-top: 10px;
}

.footer-box .social-icons i {
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
}

.footer-box .social-icons i:hover {
  color: #007bff;
}

.legal {
  text-align: center;
  margin-top: 1rem;
  padding: 10px 0;
  background-color: #343a40;
  color: #fff;
  font-size: 14px;
}

.logo-main p {
  font-family: "poppins", Arial, Helvetica, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  color: #007BFF;
  width: 150%;
  font-size: 12px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    gap: 0;
    margin: 0;
    grid-template-columns: repeat(1, 1fr);
  }

  .newsletter .flex,
  .footer-box {
    margin: 0;
    flex-direction: column;
  }

  .footer-box h3 {
    margin-top: 15px;
  }

  #unique {
    margin-top: .3rem;
  }

  .logo-main p {
    font-family: "poppins", Arial, Helvetica, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    color: #007BFF;
    width: 150%;
    font-size: 12px;
  }
}