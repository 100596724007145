.custom-marquee-container {
  padding: 10px;
  text-align: center;
  margin-top: 4rem;
}

.custom-marquee-heading {
  font-size: 20px;
  margin-bottom: 15px;
  color: #004aad;
  font-weight: bold;
}

.custom-marquee {
  margin: 15px 0;
}

.fast-custom-marquee {
  margin-top: 10px;
}

.custom-marquee-card {
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 10px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 10px;
}

.custom-linkedin-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
}

.custom-linkedin-icon img {
  width: 100%;
  height: 100%;
}

.custom-profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 15px 0 10px;
  object-fit: cover;
}

.custom-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-company-logo {
  width: 50px;
  margin-top: 8px;
  object-fit: contain;
}

.custom-button-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 10px; /* Add consistent spacing between buttons */
  justify-content: center; /* Center buttons horizontally */
}

.custom-action-button {
  transition: background-color 0.3s ease;
  background: rgb(28, 152, 237);
  background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.custom-action-button:hover {
  background: rgb(24, 72, 160);
  background: linear-gradient(251deg, rgba(24, 72, 160, 1) 35%, rgba(28, 152, 237, 1) 100%);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .custom-marquee-card {
    width: 140px;
    height: auto;
    padding: 10px;
    margin: 8px;
  }

  .custom-marquee-heading {
    font-size: 18px;
  }

  .custom-button-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .custom-action-button {
    font-size: 12px;
    padding: 6px 10px;
    margin: 5px 0;
  }

  .custom-profile-image {
    width: 60px;
    height: 60px;
    margin: 10px 0;
  }

  .custom-linkedin-icon {
    top: 5px;
    right: 5px;
    width: 12px;
    height: 12px;
  }

  .custom-company-logo {
    width: 40px;
    margin-top: 5px;
  }
}
