@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background: #D1E1FF;
  color: black
}

@layer components {
  .sub-menu {
    @apply absolute top-[4.2rem] p-[15px] rounded-[6px] origin-[50%_-170px] backdrop-blur bg-white/[0.04];
  }
}

@layer utilities {
  .flex-center {
    @apply flex items-center;
  }

  .flex-center-between {
    @apply flex items-center justify-between;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  color: black;
  scroll-behavior: smooth;
  background: #fff;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

a {
  text-decoration: none;
  color: black;
}

.Home {
  height: 100%;
  width: 100%;
}

.scrollbar-thumb {
  background-color: white !important;
  border-radius: 50%;
}

.scrollbar-track_y {
  width: 12px !important;
  background-color: rgba(84, 84, 84, 0.398) !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blur-effect {
  background-color: rgba(128, 0, 128, 0.2);
  /* Purple with 20% opacity */
  backdrop-filter: blur(20px);
  /* Adjust the blur radius */
  -webkit-backdrop-filter: blur(20px);
  /* For Safari support */
}