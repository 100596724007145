.about-us-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  background-color: #f4f8fc;
  width: 100%;
  margin-top: 4rem;
}

.about-us-left {
  flex: 2;
  margin-right: 30px;
  min-width: 300px;
}

.about-us-header {
  text-align: left;
  margin-bottom: 30px;
}

.about-us-header h1 {
  text-align: center;
  color: #003399;
  font-size: 2.5rem;
}

.about-us-header p {
  font-size: 1.125rem;
  color: #555;
}

/* Reviews Section */
.about-us-reviews {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  align-items: flex-start;
}

.review {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.review img {
  margin-right: 10px;
}

/* Right Section (Form) */
.about-us-right {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  min-width: 300px;
  max-width: 100%;
}

/* Form Styling */
.about-us-form {
  background-color: #eaf2fc;
  padding: 20px;
  border-radius: 10px;
}

.about-us-form h3 {
  margin-bottom: 20px;
  color: #003399;
}

.about-us-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.about-us-form button {
  width: 100%;
  padding: 10px;
  background-color: #003399;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.about-us-form button:hover {
  background-color: #0055cc;
}

/* Responsiveness */
@media (max-width: 1024px) {
  .about-us-container {
    padding: 0;
  }

  .about-us-left {
    margin-right: 20px;
  }

  .about-us-header h1 {
    font-size: 2rem;
  }

  .about-us-header p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .about-us-container {
    flex-direction: row;
    align-items: center;
  }

  .about-us-header h1 {
    text-align: center;
  }

  .about-us-header p {
    padding: 0 10px;
    text-align: start;
    margin-bottom: 30px;
  }

  .about-us-left {
    margin: 0 0 30px;
    text-align: center;
  }

  .about-us-right {
    padding: 0;
    width: 100%;
  }

  .about-us-form {
    padding: 15px;
    max-width: 80%;
  }
}

/* Courses Section */
.courses-section {
  margin-top: 50px;
  padding: 20px 30px;
  background-color: #fff;
  text-align: center;
}

.courses-section h2 {
  font-size: 2rem;
  color: #003399;
  margin-bottom: 20px;
}

/* Courses Container */
.courses-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Individual Course Card */
.course-card {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  padding: 20px;
  background-color: #eaf2fc;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideRight 0.5s ease-in-out;
}

.course-card h3 {
  font-size: 1.5rem;
  color: #003399;
  margin-bottom: 15px;
}

.course-card p {
  font-size: 1rem;
  color: #555;
}

/* Slide-in Animation */
@keyframes slideRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive Adjustments for Courses */
@media (max-width: 768px) {
  .courses-container {
    flex-direction: column;
    align-items: center;
  }

  .course-card {
    max-width: 80%;
    margin-bottom: 20px;
  }
}


/* Workshops Section */
.workshops-section {
  margin-top: 50px;
  padding: 20px 30px;
  background-color: #fff;
  text-align: center;
}

.workshops-section h2 {
  font-size: 2rem;
  color: #003399;
  margin-bottom: 20px;
}

/* Workshops Container */
.workshops-container {
  display: grid; /* Change to grid layout */
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Space between images */
}

/* Individual Workshop Image */
.workshop-image {
  text-align: center; /* Center text below images */
}

.workshop-image img {
  width: 100%; /* Full width of the container */
  height: 200px; /* Fixed height for uniformity */
  object-fit: cover; /* Maintain aspect ratio, cover the area */
  border-radius: 10px; /* Optional: rounded corners for images */
}

.workshop-image p {
  margin-top: 10px;
  color: #003399; /* Text color */
  font-size: 1.2rem; /* Text size */
}

/* Responsive Adjustments for Workshops */
@media (max-width: 1024px) {
  .workshops-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .workshops-container {
    grid-template-columns: 1fr; /* 1 column for small screens */
  }
}

