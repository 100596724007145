.carrer-dev-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-top: 4rem;
  }
  
  .carrer-dev-container h1 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .carrer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
  }
  
  .carrer-text {
    flex: 1;
    margin-right: 20px;
  }
  .carrer-text ol, 
.carrer-text ul {
    list-style-type: disc; /* Bullet points for unordered lists */
    list-style-position: inside; /* Ensures bullets are inside the content box */
    margin: 10px 0; /* Adds spacing between lists and surrounding content */
    padding-left: 20px; /* Indents the list items for proper alignment */
}

.carrer-text ol {
    list-style-type: decimal; /* Numerical bullets for ordered lists */
}

.carrer-text ol li, 
.carrer-text ul li {
    margin-bottom: 10px; /* Adds spacing between individual list items */
}

  
  .carrer-text ul {
    list-style-type: none;
    padding: 0;
  }
  
  .carrer-text ul li {
    margin-bottom: 10px;
  }
  
  .carrer-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .whatsapp-button,
  .expert-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .whatsapp-button {
    background-color: #25D366;
    color: white;
    border-radius: 5px;
  }
  
  .expert-button {
    transition: background-color 0.3s ease;
  background: rgb(28, 152, 237);
  background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
    color: white;
    border-radius: 5px;
  }
  .expert-button:hover {
    background: rgb(24, 72, 160);
    background: linear-gradient(251deg, rgba(24, 72, 160, 1) 35%, rgba(28, 152, 237, 1) 100%);
  }
  .carrer-image {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .carrer-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .carrer-content {
      flex-direction: column;
    }
  
    .carrer-text {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  