.slider-container {
    margin: 40px auto;
    padding: 30px 20px;
    max-width: 1100px;
    background: rgba(31, 149, 204, 0.125);
    border-radius: 15px;
    border: 1px solid gray;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .slider-heading {
    color: #134085;
    font-size: 35px;
    margin-bottom: 20px;
  }
  
  .slider-item {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .slider-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
    margin-bottom: 15px;
    padding: 8px;
  }
  
  .slider-text {
    color: rgb(19, 44, 88);
    font-size: 16px;
    margin-top: 10px;
  }
  