.popup-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-form-container {
  background: white;
  border-radius: 8px;
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.popup-close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .popup-form-container {
    flex-direction: row;
  }
}
