/* Sticky Navbar Container */
.sticky-navbar {
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  /* Center items horizontally */
  align-items: center;
  /* Center items vertically */
  height: 60px;
  border-bottom: 1px solid #ccc;
  z-index: 1000;
  margin-bottom: 5rem;
  padding: 0 10px;
  flex-wrap: nowrap;
  /* Prevent wrapping, maintain horizontal layout */
  width: 100%;
  /* Ensure full width */
  box-sizing: border-box;
  /* Include padding in width calculation */
  overflow-x: auto;
  /* Add horizontal scrolling for smaller screens */
  margin-top: 4rem;
 
}

/* Links */
.sticky-navbar a {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: color 0.3s, border-bottom 0.3s;
  white-space: nowrap;
  /* Prevents wrapping of text */
}

/* Hover Effect */
.sticky-navbar a:hover {
  background-color: #007bff;
  color: white;
}

/* Active Link Styling */
.sticky-navbar a.active {
  color: #007bff;
  position: relative;
}

/* Underline Effect for Active Link */
.sticky-navbar a.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #007bff;
}

/* Responsive Adjustments */

/* Tablets and Small Screens (Max Width: 768px) */
@media (max-width: 768px) {
  .sticky-navbar {
    padding: 10px 5px;
    /* Adjust padding for smaller screens */
    flex-direction: row;
    padding-left: 4rem; 
    /* Maintain horizontal layout */
  }

  .sticky-navbar a {
    padding: 8px 10px;
    /* Reduce padding for smaller screens */
    font-size: 14px;
    /* Adjust font size */
  }
}

/* Mobile Screens (Max Width: 480px) */
@media (max-width: 480px) {
  .sticky-navbar {
    padding: 10px 5px;
    /* Adjust padding */
    padding-left: 10rem; 
  }

  .sticky-navbar a {
    padding: 6px 8px;
    /* Further reduce padding for smaller screens */
    font-size: 12px;
    /* Smaller font size */
  }
}