.why-us {
    padding: 20px;
    text-align: center;
}

.why-us h2 {
    font-size: 30px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    width: 260px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, background-color 0.3s;
    position: relative;
    height: 250px;
}

.card-image {
    width: 50%;
    height: auto;
    margin-bottom: 10px;
    margin-left: 60px;
}

.why-us .cards-container .card .title {
    font-size: 20px !important;
    font-weight: 600;
    margin-bottom: 20px;
}

.read-more {
    align-self: center;
    margin-bottom: auto;
    margin-top: auto;
    padding: 8px 12px;
    background-color: #055A8D;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.read-more:hover {
    background-color: #377EAA;
}

/* Dialogue Box Styling */
.dialogue-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialogue-box {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.dialogue-logo {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
}

.dialogue-box h3 {
    margin-bottom: 15px;
    font-size: 24px;
}

.dialogue-box p {
    font-size: 20px;
    margin-bottom: 20px;
}

.close-dialogue {
    padding: 8px 16px;
    background-color: #055A8D;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-dialogue:hover {
    background-color: #4C92BE;
}

/* Responsive Design */
@media (max-width: 768px) {
    .cards-container {
        flex-direction: row;
        justify-content: center;
    }

    .card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .card {
        width: 48%;
        margin: 10px 1%;
    }
    .card-image {      
        margin-right: 100px;
    }
}
