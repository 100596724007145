/* MouSlider.css */
.mou-slider-container {
    margin: 40px auto;
    padding: 30px 20px;
    max-width: 1100px;
    background: rgba(31, 53, 179, 0.125);
    border-radius: 15px;
    border: 1px solid gray;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .mou-slider-heading {
    font-size: 30px;
    font-weight: 700;
    color: #005279;
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
  }
  
  .mou-slider-item {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .mou-slider-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
    margin-bottom: 15px;
    padding: 8px;
  }
  
  .mou-slider-description {
    color: rgb(0, 34, 61);
    font-size: 16px;
    margin-top: 10px;
  }
  

  @media (max-width: 768px) {
    .mou-slider-container {
      padding: 20px 10px;
    }
    /* MouSlider.css */
.mou-slider-container {

  max-width: 300px;

}

  
    .mou-slider-heading {
      font-size: 24px;
      margin-bottom: 15px;
    }
  
    .mou-slider-image {
      height: 200px;
    }
  
    .mou-slider-description {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .mou-slider-heading {
      font-size: 20px;
    }
  
    .mou-slider-description {
      font-size: 12px;
      padding: 0 5px;
    }
  }