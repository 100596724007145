.skeleton-card {
  background-color: #000;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 350px;
  height: 400px;
  overflow: hidden;
}

.skeleton-loader {
  background: linear-gradient(90deg, #65cccf 25%, #71a9f8 50%, #2ecae6 75%);
  background-size: 200% 100%;
  border-radius: 5px;
  animation: shimmer 1.2s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.skeleton-title {
  height: 20px;
  width: 80%;
  margin-bottom: 15px;
}

.skeleton-paragraph,
.skeleton-subheading {
  height: 15px;
  width: 100%;
  margin-bottom: 10px;
}

.skeleton-image {
  height: 200px;
  width: 100%;
  margin-bottom: 15px;
}

.blog-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  margin-top: 4rem;
}

.blog-card {
  background: rgb(110, 211, 247);
  background: linear-gradient(342deg, rgb(98, 142, 158) 0%, rgb(51, 177, 255) 100%);
  color: #000;
  border: .5px solid #55555556;
  width: 250px;
  height: 450px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, border 0.3s ease;
}

.blog-card:hover {
  transform: scale(1.05);
  border: 1px solid rgb(0, 94, 255);
}

.blog-card-title {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  text-align: left;
  margin-bottom: 10px;
  flex-grow: 0;
}

.blog-card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
  flex-grow: 0;
}

.blog-card-paragraph {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #000;
  font-family: 'Times New Roman', Times, serif;
}

.read-more-btn {
  display: block;
  text-align: center;
  padding: 5px;
  margin-top: auto;
  font-size: 15px;
  background-color: purple;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
}

.blog-detail-container {
  width: 96%;
  margin: 5rem auto;
  padding: 20px;
  color: #000000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blog-title {
  font-size: 2.5rem;
  text-align: start;
  margin-bottom: 20px;
  font-weight: bold;
  color: #000000dd;
}

.blog-subheading {
  font-size: 1.5rem;
  font-family: Poppins;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.553);
}

.blog-image {
  width: 100%;
  max-width: 450px;
  display: block;
  max-height: 450px;
}

.blog-paragraph {
  font-size: 1.1rem;
  color: #000000;
}

.contact-sapalogy {
  padding: 20px 0;
  max-width: 600px;
  border-radius: 10px;
  font-weight: 800;
  font-family: 'poppins';
  color: #060101a6;
}

.contact-sapalogy h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  text-align: center;
}

.contact-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.contact-item p {
  margin: 0;
  font-weight: bold;
}

.contact-item a {
  color: #007bff;
  text-decoration: none;
  word-break: break-word;
}

.contact-item a:hover {
  text-decoration: underline;
}

.blog-proposter {
  margin: 0 auto;
  max-width: 600px;
}

.blog-proposter {
  margin: 0 auto;
  max-width: 600px;
}

@media (max-width: 768px) {
  .md\\:flex {
    display: block;
  }

  .md\\:justify-around {
    justify-content: flex-start;
  }

  .contact-sapalogy {
    padding: 15px;
    max-width: 100%;
  }

  .contact-item {
    margin-bottom: 10px;
  }

  .contact-sapalogy h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .contact-sapalogy h2 {
    font-size: 1.2rem;
  }

  .contact-item a {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1024px) {
  .blog-card {
    width: 45%;
  }

  .blog-detail-container {
    width: 95%;
  }

  .blog-title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .blog-card {
    width: 100%;
    height: auto;
  }

  .blog-card-image {
    max-height: 300px;
  }

  .blog-title {
    font-size: 1.5rem;
  }

  .blog-paragraph {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .blog-title {
    font-size: 1.2rem;
  }

  .blog-lastpara {
    font-family: 'Times New Roman', Times, serif;
  }

  .blog-proposter {
    margin: 0 auto;
    max-width: 300px;
  }
}