/* Hero Section Styles */
.hero {
  background-image: url('../assets/BG1.svg');
  background-size: cover;
  background-position: center;
  padding: 150px 0;
  text-align: left;
  color: #fff;
  display: flex;
  margin-top: 0rem;
  justify-content: center;
}

.hero h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
}

.hero p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #ddd;
  line-height: 1.6;
  width: 900px;
}

.button-group {
  display: flex;
  gap: 15px;
  /* Space between buttons */
  justify-content: center;
}

/* Inquiry Button */
.inquiry-btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: rgb(28, 152, 237);
  background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
  color: #fff;
}

.inquiry-btn:hover {
  background: rgb(24, 72, 160);
  background: linear-gradient(251deg, rgba(24, 72, 160, 1) 35%, rgba(28, 152, 237, 1) 100%);
}

/* Course Button */
.course-btn {
  padding: 10px 20px;
  font-size: 14px;
  border: 2px solid rgb(28, 152, 237);
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.course-btn:hover {
  background: rgb(28, 152, 237);
  background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
  color: #fff;
}

/* Job Assistance Button */
.job-assistance-btn {
  font-size: 18px;
  width: 50%;
  padding: 1rem;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  margin: 5px auto;
  transition: background-color 0.3s ease;
  background: rgb(28, 152, 237);
  background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
  color: #fff;
}

.job-assistance-btn:hover {
  background-color: #3f88b8;
}

/* Job Assistance Section */
.job-assistance {
  padding: 1rem;
  background-color: #f9f9f9;
}

.job-assistance .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px auto;
  padding: 0;
}

.job-assistance .content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.text {
  display: grid;
  grid-template-rows: 3fr;
  max-width: 50%;
}

.job-assistance .text h2 {
  font-size: 32px;
  color: rgb(76, 146, 190);
  margin-bottom: 20px;
  text-align: center;
}

.job-assistance .text p {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.hero-ul {
  display: flex;
  flex-direction: column; /* Ensures items stack vertically */
  justify-content: center; /* Centers the UL container vertically */
  align-items: flex-start; /* Aligns the list items to the same vertical line */
  list-style: none; /* Removes bullet points */
  padding: 0;
  margin: 3px auto; /* Centers the UL container itself */
}

.hero-ul li {
  display: flex; /* Aligns icon and text horizontally within each item */
  align-items: center; /* Aligns icon and text vertically within each item */
  gap: 10px; /* Space between icon and text */
  font-size: 18px;
  color:rgb(0, 0, 0);
  margin-bottom: 10px;
  position: relative; /* Allows use of position-based adjustments */
  padding-left: 20px; /* Creates space for alignment */
}




/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    margin-top: 4rem;
    text-align: center;
    padding: 50px 20px;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .hero p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #ddd;
    line-height: -0.4;
    width: 350px;
  }

  .job-assistance-btn {
    font-size: 18px;
    width: auto;
    padding: 1rem;
    border-radius: 2rem;
    border: none;
    cursor: pointer;
    margin: 0 auto;
    transition: background-color 0.3s ease;
    background: rgb(28, 152, 237);
    background: linear-gradient(251deg, rgba(28, 152, 237, 1) 35%, rgba(24, 72, 160, 1) 100%);
    color: #fff;
  }
}

.stats {
  padding: 10px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.stats .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
}

.stat-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  width: 150px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.stat-item:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.stat-item i {
  font-size: 40px;
  color: rgb(76, 146, 190);
  margin-bottom: 10px;
}

.stat-item h3 {
  font-size: 24px;
  color: rgb(76, 146, 190);
  margin: 10px 0;
}

.stat-item p {
  font-size: 16px;
  color: #333;
}

/* Responsive Stats Adjustments */
@media screen and (max-width: 768px) {
  .stats-box {
    flex-wrap: wrap;
  }

  .stat-item {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  .job-assistance .text ul {
    text-align: start;
    list-style: none;
    padding: 34px;
    margin-bottom: 26px;
  }

  .stat-item {
    width: 100%;
  }
}

/* Additional Media Queries if Needed */
@media screen and (max-width: 768px) {
  .job-assistance .text h2 {
    font-size: 25px;
    color: rgb(76, 146, 190);
    margin-bottom: 20px;
  }

  .job-assistance .content {
    flex-direction: column;
  }

  .job-assistance .text {
    max-width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .job-assistance .image {
    max-width: 100%;
  }

  .job-assistance .text ul {
    text-align: center;
    margin-left: -0rem;
  }

  .job-assistance .text ul li:before {
    content: "✔";
    color: rgb(76, 146, 190);
    font-size: 18px;
    position: absolute;
    left: 0;
    padding-left: 30px;
    top: 0;
  }

  .form {
    margin-right: 1rem;
    width: 80%;
    margin-bottom: 2rem;
  }
}