.job-preparation {
    font-family: "Arial", sans-serif;
    margin: 20px auto;
    max-width: 80%;
    text-align: center;
margin-top: 4rem;
  }
  
  .job-preparation .main-heading {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-btn {
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 16px;
    color: #333;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .tab-btn.active {
    background-color: #4c92be;
    color: white;
    font-weight: bold;
  }
  
  .tab-btn:hover {
    background-color: #87c2e6;
    color: white;
  }
  
  .tab-content-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    text-align: left;
    color: #333;
  }
  
  .tab-content-table thead {
    background-color: #4c92be;
    color: white;
  }
  
  .tab-content-table th {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
  }
  
  .tab-content-table td {
    border: 1px solid #ddd;
    padding: 15px;
    vertical-align: top;
  }
  
  .icon {
    color: #4c92be;
    font-size: 20px;
    margin-right: 10px;
    display: inline-block;
  }
  